import {
  /* NOTE: All of these will be written to
the `window.APP_ENV_SETTINGS` object.
It is important not to export the whole STORE to
`window.APP_ENV_SETTINGS`, so just be careful
about that.
*/
  APP_BASE_URL,
  APP_LANGUAGES,
  APP_PORT,
  CHARGEBEE_PUBLISHABLE_KEY,
  CHARGEBEE_RETENTION_APP_ID,
  CHARGEBEE_RETENTION_SCRIPT,
  CHARGEBEE_SITE_URL,
  COOKIE_DOMAIN,
  DEPLOYMENT_ENVIRONMENT,
  FEATURE_TOKEN_COOKIE,
  FEATURE_TOKEN_HEADER,
  GOOGLE_PLACES_API_KEY,
  NODE_ENV,
  STORE,
} from './common.config';

const TAG_MANAGER_PROFILE =
  STORE.TAG_MANAGER_PROFILE ||
  'GTM-92L2&gtm_auth=0R2jS39J3zF0grjAFaUftA&gtm_preview=env-229';

const SENTRY_URL =
  STORE.SENTRY_URL ||
  'https://78465904bb394449b7efff181298932e@sentry.io/1186627';

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = STORE;

export {
  COOKIE_DOMAIN,
  APP_BASE_URL,
  APP_LANGUAGES,
  APP_PORT,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  DEPLOYMENT_ENVIRONMENT,
  FEATURE_TOKEN_COOKIE,
  FEATURE_TOKEN_HEADER,
  NODE_ENV,
  SENTRY_URL,
  TAG_MANAGER_PROFILE,
  GOOGLE_PLACES_API_KEY,
  CHARGEBEE_SITE_URL,
  CHARGEBEE_PUBLISHABLE_KEY,
  CHARGEBEE_RETENTION_SCRIPT,
  CHARGEBEE_RETENTION_APP_ID,
};
