import type { EcommerceStatus } from '@__graphql__/types';

import { NODE_ENV } from '../../config/client.config';

export const pushData = (data: Record<string, unknown>): void => {
  if (window?.dataLayer) {
    window.dataLayer.push(data);

    if (NODE_ENV === 'development') {
      // biome-ignore lint/suspicious/noConsole: We want this
      console.info('dataLayer.push', data);
    }
  } else {
    if (NODE_ENV === 'development') {
      // biome-ignore lint/suspicious/noConsole: We want this
      console.info('Ignoring dataLayer.push request on server side', data);
    }
  }
};

interface DataLayer {
  isAuthenticated: boolean;
  country?: string;
  language: string;
  pageCategory: string;
}

export const pushUserData = ({
  isAuthenticated,
  country,
  language,
  pageCategory,
}: DataLayer): void => {
  pushData({
    authentication: isAuthenticated ? 'Logged in' : 'Anonymous',
    country,
    lang: language,
    pageCategory,
    pageUrl: window.location.href,
  });
};

interface Product {
  productId?: string | null;
  productName?: string | null;
  quantity?: number | null;
  price?: number | null;
  category: string;
  subCategory: string;
}

export const pushFunnelEvent = (step: string, products?: Product[]) => {
  pushData({ products: null });
  pushData({
    event: 'funnel',
    step,
    type: '',
    products,
  });
};

export const pushLegacyEcommerceEvent = (
  action: string,
  ecommerce: Record<string, unknown>,
): void => {
  pushData({
    ecommerce: null,
  });
  pushData({
    event: 'analyticsEvent',
    eventCategory: 'Ecommerce',
    eventAction: action,
    ecommerce,
  });
};

// From https://developers.google.com/analytics/devguides/collection/ga4/reference/events
type EcommerceEvent =
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'add_shipping_method'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'earn_virtual_currency'
  | 'generate_lead'
  | 'join_group'
  | 'level_end'
  | 'level_start'
  | 'level_up'
  | 'login'
  | 'post_score'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'search'
  | 'select_content'
  | 'select_item'
  | 'select_promotion'
  | 'share'
  | 'sign_up'
  | 'spend_virtual_currency'
  | 'tutorial_begin'
  | 'tutorial_complete'
  | 'unlock_achievement'
  | 'view_cart'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'review_order';

type EcommerceData = {
  cart_id?: string;
  currency: string; // Currency of the items associated with the event, in 3-letter ISO 4217 format.
  transaction_id?: string; // The unique identifier of a transaction.
  value: number; // The monetary value of the event.
  coupon?: string; // The coupon name/code associated with the event.
  shipping?: number; // Shipping cost associated with a transaction.
  shipping_tier?: string; // Shipping method
  payment_type?: string; // Payment type
  tax?: number; // Tax cost associated with a transaction.
  items: Array<Item>; // The items for the event.
  customer_type?: EcommerceStatus;
};

type Item = {
  item_id?: string | null; // The ID of the item.
  item_name?: string | null; // The name of the item.
  item_version?: string | null; // The version of the item, e.g. "Full version".
  affiliation?: string | null; // A product affiliation to designate a supplying company or brick and mortar store location.
  coupon?: string | null; // The coupon name/code associated with the item.
  discount?: number | null; // The monetary discount value associated with the item.
  index?: number | null; // The index/position of the item in a list.
  item_brand?: string | null; // The brand of the item.
  item_variant?: string | null; // The item variant or unique code or description for additional item details/options.
  location_id?: string | null; // The physical location associated with the item (e.g. the physical store location). It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used.
  price?: number | null; // The monetary price of the item, in units of the specified currency parameter.
  quantity?: number | null; //	Item quantity
  sku_id?: string | null; // The SKU of the product / variant
  sku_name?: string | null; // The name of the product / variant
  sdbs_id?: string | null; // The SDBS ID of the product / variant.
};

export const pushPerpetualEcommerceEvent = (
  event: EcommerceEvent,
  {
    transaction_id,
    affiliation = 'native instruments',
    cart_id,
    currency,
    value,
    tax,
    coupons,
    items,
    shipping_tier,
    shipping,
    payment_type,
    customer_type,
  }: {
    transaction_id?: string;
    affiliation?: string;
    cart_id?: string;
    currency?: string | null;
    value?: number | null;
    tax?: number | null;
    coupons?: (string | undefined)[];
    items?: Item[] | null;
    shipping_tier?: string | null;
    shipping?: number | null;
    payment_type?: string | null;
    customer_type?: EcommerceStatus;
  },
) => {
  pushData({
    ecommerce: null,
  });

  const ecommerce: EcommerceData = {
    currency: currency || '',
    value: value || 0,
    items:
      items?.map((item) => {
        const quantity = item?.quantity || 1;
        const price = item.price || 0;

        return {
          item_id: item.item_id || '',
          item_name: item.item_name || '',
          item_version: item?.item_version || '',
          sku_id: item.sku_id || '',
          sku_name: item.sku_name || '',
          sdbs_id: item.sdbs_id || '',
          affiliation: affiliation || 'native instruments',
          item_brand: item.item_brand || 'native instruments',
          price: price || 0,
          quantity: quantity || 1,
        };
      }) || [],
  };

  if (cart_id) {
    ecommerce.cart_id = cart_id;
  }
  if (transaction_id) {
    ecommerce.transaction_id = transaction_id;
  }
  if (tax) {
    ecommerce.tax = tax;
  }
  if (coupons && coupons.length) {
    ecommerce.coupon = coupons.join(';');
  }
  if (shipping_tier) {
    ecommerce.shipping_tier = shipping_tier;
  }
  if (shipping !== undefined && shipping !== null) {
    ecommerce.shipping = shipping;
  }
  if (payment_type) {
    ecommerce.payment_type = payment_type;
  }
  if (customer_type) {
    ecommerce.customer_type = customer_type || 'unknown';
  }
  pushData({
    event,
    product_type: 'perpetual',
    ecommerce,
  });
};
